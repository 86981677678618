export function useInfoToast(title: string, description: string) {
  if (import.meta.server) {
    return;
  }

  const toast = useToast();
  toast.add({ icon: "i-heroicons-chat-bubble-oval-left", title: title, description: description, color: "primary" });
}

export function useSuccessToast(title: string, description: string) {
  if (import.meta.server) {
    return;
  }

  const toast = useToast();
  toast.add({ icon: "i-heroicons-check", title: title, description: description, color: "green" });
}

export function useFailToast(title: string, description: string, options?: { timeout?: number }) {
  if (import.meta.server) {
    return;
  }

  const toast = useToast();
  toast.add({
    icon: "i-heroicons-exclamation-triangle",
    title: title,
    description: description,
    color: "red",
    timeout: options?.timeout,
  });
}
